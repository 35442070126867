
import { defineComponent, watch } from "vue";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import Card from "primevue/card";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import AddShippingAddressDialog from "@/components/Pos/AddShippingAddressDialog.vue";
import ShipToAddressesTable from "./ShipToAddressesTable.vue";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import Carousel from "primevue/carousel";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();
import Utils from "@/utility/utils";


export default defineComponent({
  name: "ShippingInfo",
  components: {
    ShipToAddressesTable,
    Card,
    AddShippingAddressDialog,
    Button,
    ProgressSpinner,
    CollapsibleSection,
    Carousel,
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      hideUseNewAddressButton: "mrkControl/hideUseNewAddress",
      allowAddressOverride: "mrkControl/allowAddressOverride",
      showShipToId: "mrkControl/showShipId",  
      getSalesOrder: "pos/getSalesOrder",
      getClient: "session/getClient",
      getWebAccess: "session/getWebAccess",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
      posShowOnlyShipTo: "mrkControl/posShowOnlyShipTo",
      posAutoCreateCust: "mrkControl/posAutoCreateCust",
    }),
    customerModuleAccess(): boolean {
      return this.getWebAccess("CUST.Q");
    },
    getAddress(): any {
      if(this.editMode){
        return this.addresses.find((address) => address.ship_seq === this.getSalesOrder.ship_seq);
      }else{
        let address = {
          ship_seq: "",
          ship_city: "",
          ship_state: "",
          ship_zip: "",
          ship_name: "",
          ship_country: "",
          ship_attn: "",
          ship_comm: "",
          ship_to_items: [{ ship_to: "" }],
          ship_email: "",
          ship_phone: "",
          ship_fax: "",
          ship_to_tax_items: [],
          ship_acct_no_items: [],
        }
        return address;
      }
    },
    maxCarouselPage(): number {
      // Really should only be the second condition, but the first is a workaround since the carousel does not
      // load immediately and the totalIndicators is not available until after the first render
      if(this.$refs.carousel === undefined) {
        return Math.ceil(this.addresses.length / this.scrollLength) - 1;
      } else {
        const totalIndicators = (this.$refs.carousel as any).totalIndicators;
        if(totalIndicators > 1) {
          return totalIndicators - 1;
        } else {
          return 0;
        }
      }
    },
  },
  async created() {
    this.validateSalesOrder({metaData: {validate_only: "Y", validate_reason: "CUSTOMER_SELECTED"}});
    if (this.getSalesOrder.ship_seq != null) {
      this.getTaxes();
    }
    this.shipAddressIndex = this.addresses.findIndex((address: any) => {
      return address.ship_seq === this.getSalesOrder.ship_seq;
    });

    if(this.shipAddressIndex === -1) {
      this.page = 0;
    } else {
      this.page = Math.floor(this.shipAddressIndex / this.scrollLength);
    }
  },
  mounted() {
    this.updateAddresses();
  },
  data() {
    return {
      shipAddressIndex: -1,
      addresses: [] as any[],
      page: 0,
      scrollLength: 2,
      setFirst: false,
      editMode: false,
      carouselResponsiveOptions: [
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 2,
        },
        {
          breakpoint: "991px",
          numVisible: 3,
          numScroll: 2,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 2,
        },
      ],
      sectionsStatus: {
        addressesInfo: true,
      },
      isLoading: false,
      thirdPartyList: Array<any>(),
      showAddAddressDialog: false,
      showOverrideAddressDialog: false,
      modalHeader: "",
      disabledField: false,
    };
  },
  methods: {
    ...mapActions({
      getTaxes: "pos/getTaxes",
      setCustomer: "pos/setCustomer",
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    selectedShipSeq() {
      if (this.getSalesOrder) {
        return this.getSalesOrder.ship_seq;
      } else {
        return "";
      }
    },
    handleOnClose() {
      this.setFirst = true;
    },
    handleUpdatePage(event: any) {
      if(event > this.page) {
        (this.$refs.carousel as any).navForward({}, event);
      } else {
        (this.$refs.carousel as any).navBackward({}, event);
      }

      this.page = event;
    },
    handleRowClick(event: any) {
      this.setFirst = false;
      this.setShipAdr(event.data);
    },
    getSectionIconClass(
      status: { [key: string]: boolean },
      section: "addressesInfo"
    ) {
      return status[section]
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    toggleSectionIcon(tab: "addressesInfo") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    isAddressSelected(data: any) {
      if (data.ship_seq === this.getSalesOrder.ship_seq) {
        return true;
      } else {
        return false;
      }
    },
    getCardTitleClass(data: any) {
      if (this.isAddressSelected(data)) {
        return "m-1 selected-address-card shadow-4";
      }
      return "m-1 address-card shadow-4";
    },
    setShipAdr(address: any) {
      const originalOrder = JSON.parse(JSON.stringify(this.getSalesOrder));

      Utils.setOrderAddress(this.getCustomer, this.getSalesOrder, address, this.getSalesTaxCodes);
      this.getTaxes();
      this.validateSalesOrder({ oldSalesOrder: originalOrder, metaData: {
        validate_only: "Y", 
        validate_reason: "SHIP_SEQ", 
        validate_id: this.getSalesOrder.ship_seq
      }})
      this.updateAddresses();
      this.handleUpdatePage(0);
    },
    formattedAddress(data: any) {
      return Utils.customerShippingAddress({
        ship_seq_items: [data],
      } as any).join("\n");
    },
    openAddAddressDialog() {
      this.editMode = false;
      this.disabledField = false;
      this.showAddAddressDialog = true;
      this.modalHeader = "Use New Address";
    },
    closeAddressDialog() {
      this.showAddAddressDialog = false;
      this.modalHeader = "";
    },
    openOverrideAddressDialog() {
      this.modalHeader = "Edit Address";
      this.editMode = true;
      this.disabledField = true;
      this.showAddAddressDialog = true;
    },
    updateAddresses() {
      let addresses = [];
      if(!this.posShowOnlyShipTo) {
        const mailingAddress = Utils.getCustomerMailAddress(this.getCustomer);
        addresses.push(mailingAddress);
      }

      if(this.getCustomer?.ship_seq_items && this.getCustomer.ship_seq_items.length > 0) {
        addresses = [...addresses, ...this.getCustomer.ship_seq_items];
      }

      addresses = addresses.map((addr) => {
        if (addr.ship_seq === this.getSalesOrder.ship_seq) {
          return {
            ship_seq: this.getSalesOrder.ship_seq,
            ship_name: this.getSalesOrder.ship_name,
            ship_attn: this.getSalesOrder.ship_attn,
            ship_comm: this.getSalesOrder.ship_comm,
            ship_city: this.getSalesOrder.ship_city,
            ship_state: this.getSalesOrder.ship_state,
            ship_zip: this.getSalesOrder.ship_zip,
            ship_country: this.getSalesOrder.ship_country,
            ship_email: this.getSalesOrder.contact_email,
            ship_phone: this.getSalesOrder.ship_phone,
            ship_fax: this.getSalesOrder.ship_fax,
            ship_to_items: this.getSalesOrder.ship_address_items.map((item: any) => {
              return { ship_to: item.ship_address };
            }),
            ship_to_tax_items: this.getSalesOrder.tax_codes_items?.map((item: any) => {
              return { ship_to_tax: item.tax_codes};
            }),
            //This was not being returned in the getSalesOrder but should be accounted for
            // In the utils this is being returned as a empty array at all times
            // ship_acct_no_items: this.getSalesOrder.acct_no_items.map((item: any) => {
            //   return { ship_acct_no: item.acct_no, ship_carrier: item.carrier};
            // }),
          };
        }
        return addr;
      });

      addresses = addresses.sort((a, b) => {
        if (a.ship_seq === this.getSalesOrder.ship_seq) {
          return -1;
        } else if (b.ship_seq === this.getSalesOrder.ship_seq) {
          return 1;
        } else {
          return 0;
        }
      });

       this.addresses = addresses;
    },
    async saveNewAddress(data: any, addNewAddress = false) {
      if (this.editMode) {
        const newAddr = this.addresses.find((addr) => addr.ship_seq === data.ship_seq);
        if (newAddr) {
          this.addresses = this.addresses.map((addr) => {
            if (addr.ship_seq === data.ship_seq) {

              return data;
            }
            return addr;
          });
        }
        Utils.setOrderAddress(this.getCustomer, this.getSalesOrder, data, this.getSalesTaxCodes);
        this.closeAddressDialog();
        this.getTaxes();
      } else {
        if (addNewAddress) {
          let [oldCust, newCust] = [{}, {}];

          if (!this.getCustomer.ship_seq_items) {
            this.getCustomer.ship_seq_items = [];
          }

          let oldShipSeq = [...this.getCustomer.ship_seq_items];
          let nextSeqItemId = (
            this.getCustomer.ship_seq_items
              .map((element: any) => element.ship_seq)
              .reduce((a: any, b: any) => Math.max(a, b), -Infinity) + 1
          ).toString();


          if (nextSeqItemId === 'NaN') {
            nextSeqItemId = (this.getCustomer.ship_seq_items.length + 1).toString();
          }

          data.ship_seq = nextSeqItemId;

          newCust = {
            cust_id: this.getCustomer.cust_id,
            ship_seq_items: [...oldShipSeq, data],
          };
          oldCust = {
            cust_id: this.getCustomer.cust_id,
            ship_seq_items: [...oldShipSeq],
          };

          await store
            .dispatch("customerInquiry/updateCustomer", {
              custId: this.getCustomer.cust_id,
              oldCust,
              newCust,
              Client: store.getters["session/getClient"],
              populateBlankMailingAddress: this.posAutoCreateCust ? "Y" : "",
            })
            .then((response) => {
              customerService.getCustomer(this.getCustomer.cust_id, this.getClient, "contact_email contact_name").then((response: any) => {
                this.setCustomer({ cust_items: [response] });
                Utils.setOrderAddress(this.getCustomer, this.getSalesOrder, data, this.getSalesTaxCodes);
                this.updateAddresses();
              })
                .catch((response) => {
                  store.dispatch("notification/add", {
                    message: `An error occurred while getting the customer.`,
                    type: "error",
                  });
                });

            })
            .catch((response) => {
              store.dispatch("notification/add", {
                message: `An error occurred while adding the address.`,
                type: "error",
              });
            });
        } else {
          data.ship_seq = "NEW" + this.getCustomer.ship_seq_items.length.toString();
          this.getCustomer.ship_seq_items = [
            data,
            ...this.getCustomer.ship_seq_items
          ]
          this.updateAddresses();
        }

        this.getTaxes();
        this.closeAddressDialog();
      }
    }
  },
});
