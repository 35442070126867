
import { defineComponent, PropType } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";
import { isEqual } from "lodash";

import Dialog from "primevue/dialog";
import Button from "primevue/button";

import PosLineItemsTable from "@/components/Pos/PosLineItemsTable.vue";
import MessageBox from "@/components/MessageBox.vue";
import { FDICT_PARTS } from "@/utility/fdicts/parts";

interface lineItem { 
  key: string;
  lis: string;
}

export default defineComponent({
  name: "POSCartDialog",
  components: {
    Button,
    Dialog,
    MessageBox,
    PosLineItemsTable,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedLineItemIndex: {
      type: Number,
      default: -1,
    },
    lineItems: {
      type: Array as PropType<lineItem[]>,
      default: () => [],
    },
    getLineItemChanges: {
      type: Function,
      required: true
    },
    lineItemCustomFields: {
      type: Array,
      default: () => [],
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    confirmAndCloseText: {
      type: String,
      default: "Save & Close",
    },
  },
  emits: ['update:modelValue', "onRowClick", "onRemovePartClick", "onCloseDialog", "onSave"],
  data() {
    return {
      filters: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      showDiscardChangesDialog: false,
      closeDialog: false,
      showRemoveConfirm: false,
      mostRecentLineItems: [] as any[],
      discardChangesMessage: "",
      windowWidth: window.innerWidth,
      indexToRemove: -1,
    };
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getFieldLabel: "fdict/getFieldLabel",
      posMaintainCartSort: "mrkControl/posMaintainCartSort",
    }),
    dialogWidth() {
      if(this.lineItemCustomFields.length > 1) {
        return '98%';
      }else{
        if (this.windowWidth > 1200) { // Example breakpoint for large screens
        return '70%';
        } else { // Small screens
          return '98%';
        }
      }
    },
    partNumberLabel() {
      return this.getFieldLabel("PARTS", FDICT_PARTS.PART_NO, "Part #");
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions({
    }),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleMostRecentLineItems(event: any) {
      this.mostRecentLineItems = event;
    }, 
    handleCloseDialog() {
      const editingRows = this.calculateChanges();
      if (editingRows.length > 0) {
        this.discardChangesMessage = this.getDiscardChangesMessage(editingRows);
        this.closeDialog = true;
        this.showConfirmDiscardChanges();
      } else {
        this.$emit('update:modelValue', false);
      }
      this.$emit("onCloseDialog");
      this.filters.global.value = null;
    },
    showConfirmDiscardChanges() {
      this.showDiscardChangesDialog = true;
    },
    discardChanges() {
      this.showDiscardChangesDialog = false;
      if (this.closeDialog) {
        this.$emit('update:modelValue', false);
        this.$emit("onCloseDialog");
      }
    },
    compareLineItem(previousItem: any, currentItem: any) {
      const hasQuantityChanged = +currentItem.li_order_qtys !== +previousItem.li_order_qtys;
      const hasPriceChanged = +currentItem.li_prices !== +previousItem.li_prices;
      const hasDescriptionChanged = currentItem.wrap_desc !== previousItem.wrap_desc;
      const hasNotesChanged = currentItem.li_notes !== previousItem.li_notes;

      let hasCustomFieldsChanged = false;
      if (previousItem.custom_fields && currentItem.custom_fields) {
        for (const key of Object.keys(previousItem.custom_fields)) {
          if (currentItem.custom_fields[key] !== undefined 
            && !isEqual(previousItem.custom_fields[key], currentItem.custom_fields[key])) {
            hasCustomFieldsChanged = true;
            break; 
          }
        }
      }
      return hasQuantityChanged || hasPriceChanged || hasDescriptionChanged || hasNotesChanged || hasCustomFieldsChanged;
    },
    calculateChanges() {
      //check to see if line item has been edited compared against the sales order
      let editingRows = [] as any[];
      this.lineItems.forEach((prevItem: any) => {
        const matchingRecentItem = this.mostRecentLineItems.find((recentItem: { lis: string; key: string; }) => recentItem.lis === prevItem.lis && recentItem.key === prevItem.key);
        if (matchingRecentItem) {
          const hasChanged = this.compareLineItem(matchingRecentItem, prevItem);
        
          if (hasChanged) {
            const isUniqueLineItem = !editingRows.some((filteredItem: { key: string; lis: string;}) => filteredItem.lis === prevItem.lis && filteredItem.key === prevItem.key);
            if (isUniqueLineItem) {
              editingRows.push(matchingRecentItem);
            }
          }
        } else {
          editingRows.push(prevItem);
        }
      });
      return editingRows;
    },
    getDiscardChangesMessage(editingRows: any[]) {
      const partsToDiscard = editingRows
        .map((row: any) => {
          if (!this.mostRecentLineItems.some((item: { key: string; lis: string; }) => item.key === row.key && item.lis === row.lis)) {
            return `${this.partNumberLabel} ${row.li_parts}\r\n${
              (row.wrap_desc !== undefined && row.wrap_desc !== "") ? `Description: ${row.wrap_desc}\r\n` : ""
            }Line has been removed\r\n`
          }

          const originalItem = this.lineItems.find((item: { key: string; lis: string; }) => item.key === row.key && item.lis === row.lis);
          
          const changes = this.getLineItemChanges(originalItem, row);

          let formattedChanges;

          if (changes.length === 1) {
            formattedChanges = changes[0];
          } else if (changes.length === 2) {
            formattedChanges = changes.join(' and ');
          } else {
            formattedChanges = `${changes.slice(0, -1).join(', ')}, and ${changes[changes.length - 1]}`;
          }

          return `${this.partNumberLabel} ${row.li_parts}\r\n${
              (row.wrap_desc !== undefined && row.wrap_desc !== "") ? `Description: ${row.wrap_desc}\r\n` : ""
          }${formattedChanges} ${changes.length > 1 ? "have" : "has"} been modified\r\n\u200B\r\n`
        })
        .join("\n\n");

      return `Are you sure you want to discard the changes?\r\n${partsToDiscard}`;
    },
    onSaveCartChangeAndClose() {
      this.$emit('onSave', this.mostRecentLineItems);
      this.$emit('update:modelValue', false);
      this.$emit("onCloseDialog");
    },
    onSaveCartChange() {
      this.$emit('onSave', this.mostRecentLineItems);
      this.$emit("onCloseDialog");
    },
  },
  watch: {
    selectedLineItemIndex(newValue: number) {
      if(this.mostRecentLineItems.length === 0 && newValue === -1){
        this.$emit('update:modelValue', false);
      }
    },
  },
});
