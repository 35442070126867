import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showUnsavedOrdersDialog,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showUnsavedOrdersDialog) = $event)),
    header: "Would you like to create any unsaved orders?",
    modal: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Create All",
        onClick: _ctx.createOrderForUnsavedOrder,
        disabled: !this.isOnline,
        loading: _ctx.isLoading,
        "data-testid": "unsavedOrderButton"
      }, null, 8, ["onClick", "disabled", "loading"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DataTable, {
          value: _ctx.unsavedOrders,
          style: {"margin":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "cust_id",
              header: "ID"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.order.sold_to), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "cust_name",
              header: "Customer Name"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.customer_name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "order_amount",
              header: "Order Amount"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.order.order_amount), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "order_quantity",
              header: "Total Quantity"
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(slotProps.data.order?.lis_items?.reduce(
                (acc, item) => acc + +item.li_order_qtys,
                0
              )), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}