<template>
  <div class="grid">
    <div class="col-12 mt-0 mr-0 ml-0 surface-100 flex justify-content-between flex-wrap">
      <div class="my-2">
        <Search
          ref="search_invoice" 
          label="name" 
          file="AR" 
          currentView="Pos" 
          placeholder="Search for Invoices" 
          :leftIcon="true" 
          :scannerMode="true"
          class="large-search-input" 
          :searchFunction="lookUpInvoice"
        />
      </div>
      <Button
          class="ml-1 my-2"
          label="Load All Invoices"
          icon="pi pi-search"
          @click="handleLoadAllInvoices"
        />
    </div>
    <div class="col-12 md:col-4 xl:col-3 pb-1 pr-3 pl-2 md:p-0 md:px-2 mt-2">
      <Receivables 
        title="Aging Summary"
        :items="[
          {label: 'Current', value: agingSummary.current, color: '#58b41c'},
          {label: '31-60', value: agingSummary.over_thirty, color:'#04789f'},
          {label: '61-90', value: agingSummary.over_sixty, color:'#fa332e'},
          {label: '90+', value: agingSummary.over_ninety, color:'#ca0c04'},
        ]"
        :total="{label: 'Balance', value: agingSummary.balance, color:'#8A2BE2'}"
        :showBars="false"
        :showTotal="true"
        :roundTotals="false"
        :selectedIdx="selectedIdx"
        @row:click="handleRowClick"
      />
      <Button
        v-if="!disable_downloading_statements"
        icon="pi pi-download"
        label="Download Statement"
        class="m-1"
        :style="{ width: '95%' }"
        @click="getPDFStatement"
        :loading="loadingStatement"
      />
      <Button
        v-if="!disable_emailing_statements"
        icon="pi pi-envelope"
        label="Email Statement"
        class="m-1"
        :style="{ width: '95%' }"
        @click="showEmailPDFDialog = true"
        :loading="emailLoading"
      />
    </div>
    <div class="col-12 md:col-8 xl:col-9 p-0 pr-1 m-0 ">
      <Card>
        <template #content>
          <LoadingSpinner :loading="loading" />
          <div class="mt-0">
            <!-- BEGIN DATATABLE -->
            <DataTable
              v-if="!loading"
              class="p-datatable-sm"
              filterDisplay="menu"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              selectionMode="single"
              v-model:first="first"
              v-model:filters="filters"
              :rowClass="isSelectedRow"
              :paginator="true"
              :rows="rows"
              :value="this.invoices"
              :rowsPerPageOptions="[5, 10, 25, 50]"
              @row-click="rowClick"
              @page="handlePage"
            >
              <template #empty> {{emptyTableLabel}}. </template>
              <Column field="ar_id" header="Invoice" class="ar-id" sortable>
                <template #body="{ data }">
                  {{ data.ar_id }}
                </template>
              </Column>
              <Column field="invoice_date" header="Invoice Date" sortable>
                <template #body="{ data }">
                  {{ formatStringDate(data.invoice_date) }}
                </template>
              </Column>
              <Column field="due_date" header="Due Date" sortable>
                <template #body="{ data }">
                  {{ formatStringDate(data.due_date) }}
                </template>
              </Column>
              <Column
                v-if="this.invoices.some(ar => ar.invoice_amt)"
                field="invoice_amt"
                header="Invoice Amount"
                bodyStyle="text-align: right"
              >
                <template #body="{ data }">
                  <span class="mr-4">{{
                    formatCurrency(data.invoice_amt || 0)
                  }}</span>
                </template>
              </Column>
              <Column
                field="balance"
                header="Balance"
                sortable
                bodyStyle="text-align: right"
              >
                <template #body="{ data }">
                  {{ formatCurrency(data.balance || 0) }}
                </template>
              </Column>
              <Column
                field="status"
                header="Status"
                sortable
                >
                <template #body="data">
                  {{ displayStatus(data.data) }}
                </template>
              </Column> 
              <Column
                v-if="!this.hidePoNo"
                field="po"
                header="PO Number"
              >
              </Column>
              <Column>
                <template #body="{ data }">
                  <Button
                    v-tooltip.top="`Download Invoice ${data.ar_id}`"
                    class="p-button-sm mx-1"  
                    @click="fetchPDFInvoice(data.ar_id)"
                    :icon="invoiceIcon(data.ar_id)"
                  />
                </template>
              </Column>
            </DataTable>
            <!-- END DATATABLE -->
          </div>
        </template>
      </Card>
    </div>
  </div>
    <EmailFileDialog
      :show="showEmailPDFDialog"
      :header="'Email Statement ' + getCustomer.name"
      :fileId="getCustomer.cust_id"
      :fileName="'statement.pdf'"
      :contacts="getCustomer.contact_id_items"
      @onEmail="sendEmail"
      @hide="showEmailPDFDialog = false"
      @onAddressAdded="handleAddressAdded"
    />
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Tooltip from "primevue/tooltip";
import Card from "primevue/card";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import Receivables from "@/components/UI/Receivables.vue";

import InvoiceService from "@/services/InvoiceService";
import ARService from "@/services/ARService";
import Utils from '@/utility/utils';
import Search from "@/components/Search.vue";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import CustomerService from "@/services/CustomerService";

import EventBus, {AppEvents} from "@/utility/EventBus"

const custService = new CustomerService();
const invoiceService = new InvoiceService(process.env.VUE_APP_ABSTRACTION_API);
const arService = new ARService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Invoices",
  components: {
    DataTable,
    Column,
    LoadingSpinner,
    Card,
    Button,
    EmailFileDialog,
    Search,
    Receivables,
  },
  directives: {
    tooltip: Tooltip,
  },
  mounted() {
    if(!this.manuallyLoadOrders) {
      this.fetchInvoices(false);
    }
    this.agingLoading = false;
    arService
      .getAging(this.getCustomer.cust_id)
      .then((response) => {
        if (response.aging) {
          const agingList = response.aging.find(
            (aging) => aging.cust_id === this.getCustomer.cust_id
          );
          if (agingList) {
            this.agingSummary = agingList;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.agingLoading = false;
      });
    this.$refs.search_invoice.$refs.autocomplete.$el.children[0].focus()

    this.getMrkControl({
      client: store.getters["session/getClient"],
      selectedCode: this.getSelectedCoCode,
      refresh: false,
    });
    EventBus.on(AppEvents.PosReloadInvoices, () => {
      this.fetchInvoices(false);
    });
  },
  beforeUnmount() {
    EventBus.off(AppEvents.PosReloadInvoices);
  },
  computed: {
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "pos/getCustomer",
      getClient: "session/getClient",
      disable_downloading_statements: "mrkControl/disableDownloadingStatements",
      disable_emailing_statements: "mrkControl/disableEmailingStatements",
      manuallyLoadOrders: "mrkControl/manuallyLoadOrders",
      hidePoNo: "mrkControl/hideInvoicesPoNo",
    }),
  },
  data() {
    return {
      emptyTableLabel: 'Invoices have not been loaded',
      loading: false,
      selectedStatus: "",
      showEmailPDFDialog: false,
      sortValue: "",
      selectedDateRange: "",
      rangeStart: 1,
      rangeEnd: 100,
      first: 0,
      rows: 5,
      page: 1,
      sortOrder: 1,
      sortField: "",
      sortOrderString: "",
      invoices: [],
      status: [
        { name: "Outstanding", code: "O" },
        { name: "Paid", code: "P" },
        { name: "Payment Pending", code: "Y" },
      ],
      invoicesView: [],
      loadingPDFInvoices: [],
      loadedPDFs: [],
      agingSummary: {},
      agingLoading: false,
      loadingStatement: false,
      emailLoading: false,
      dateStart: '',
      dateEnd: '',
      selectedIdx: -1,
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      addPartToOrder: "pos/addPartToOrder",
      setCust: "pos/setCustomer",
      setReg: "pos/setRegister",
      setCustomerContacItems: 'pos/setCustomerContacItems',
      setLastItemChanged: "pos/setLastItemChanged",
      getMrkControl: "mrkControl/getMrkControl",
    }),
    handleRowClick(data) {
      switch (data.label) {
        case 'Current':
          this.setDatesToFetch(30, -1);
          break;
        case '31-60':
          this.setDatesToFetch(60, 31);
          break;
        case '61-90':
          this.setDatesToFetch(90, 61);
          break;
        case '90+':
          this.setDatesToFetch(45000, 91);
          break;
      
        default:
          this.dateStart = '';
          this.dateEnd = '';
          break;
      }
      this.sortOrderString = 'by-dsnd '
      this.sortValue = 'due.date'
      this.fetchInvoices(false);
    },
    setDatesToFetch(start, end) {
      let dateStart = new Date();
      let dateEnd = new Date();
      dateStart.setDate(dateStart.getDate() - start);
      dateEnd.setDate(dateEnd.getDate() - (end < 0 ? -365 : end));
      this.dateStart = Utils.roverDateString(dateStart, true);
      this.dateEnd = Utils.roverDateString(dateEnd, true);
    },
    handleLoadAllInvoices() {
      this.selectedIdx -= 1;
      this.dateStart = '';
      this.dateEnd = '';
      this.sortOrderString = 'by-dsnd '
      this.sortValue = 'due.date'
      this.fetchInvoices(false);
    },
    rowClick(event) {
      let inv = JSON.parse(JSON.stringify(event.data));
      inv.type = "IN";
      if (
        this.getOrder.filter((item) => item.ar_id === event.data.ar_id)
          .length == 0
      ) {
        this.addPartToOrder(inv);
      } else {
        this.setLastItemChanged('').then(() => {
          this.setLastItemChanged(inv.ar_id)
        })
      }
    },
    sendEmail(data) {
      this.emailLoading = true;
      const cuttoff_date = Utils.roverDateString(new Date());
      arService
        .getStatement(this.getCustomer.cust_id, cuttoff_date, data)
        .then((response) => {
          if(response === 'success') {
            this.addNotification({
              message: `Statement has been emailed successfully`,
              type: "success",
            });
          }
          else {
            this.addNotification({
              message: `Statement was not sent`,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.addNotification({
            message: `Statement could not be sent: ${err}`,
            type: "error",
          });
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },
    getPDFStatement() {
      this.loadingStatement = true;
      const cuttoff_date = Utils.roverDateString(new Date());
      arService
        .getStatement(this.getCustomer.cust_id, cuttoff_date)
        .then((response) => {
          const bufferArray = Utils.base64ToArrayBuffer(response);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
        })
        .catch((err) => {
          this.addNotification({
            message: `Failed to download statement. Please try again`,
            type: "error",
          });
        })
        .finally(() => {
          this.loadingStatement = false;
        });
    },
    fetchPDFInvoice(invoiceId) {
      const pdf = this.invoicesView?.find(
        (invoice) => invoice.invoice_num === invoiceId
      );

      if (pdf !== undefined) {
        window.open(pdf.pdf, "_blank");
      } else {
        this.loadingPDFInvoices?.push(invoiceId);

        invoiceService
          .getInvoice(invoiceId)
          .then((response) => {
            const bufferArray = Utils.base64ToArrayBuffer(response);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            this.invoicesView?.push({ invoice_num: invoiceId, pdf: data });

            this.updatePDFLoadingState(invoiceId);

            window.open(data, "_blank");
          })
          .catch(() => {
            this.updatePDFLoadingState(invoiceId);
          });
      }
    },
    invoiceIcon(invoiceId) {
      let downloaded = this.invoicesView;
      let downloading = this.loadingPDFInvoices.includes(invoiceId);
      return {
        "pi pi-download":
          downloaded.find((i) => i.invoice_num === invoiceId) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i) => i.invoice_num === invoiceId) !== undefined &&
          !downloading,
      };
    },
    updatePDFLoadingState(invoiceId) {
      const index = this.loadingPDFInvoices.indexOf(invoiceId, 0);
      if (index > -1) this.loadingPDFInvoices.splice(index, 1);
    },
    fetchInvoices(isAddingInvoices) {
      this.loading = true;
      if (isAddingInvoices) {
        this.rangeStart += 100;
        this.rangeEnd += 100;
      }
      invoiceService
        .setInvoices(
          this.rangeStart,
          this.rangeEnd,
          this.getCustomer.cust_id,
          this.getClient,
          this.dateStart,
          this.dateEnd,
          this.selectedStatus,
          this.sortOrderString + this.sortValue,
          this.id,
          this.type,
          "so_total so_status"
        )
        .then((response) => {
          if (isAddingInvoices) {
            this.invoices.push(...response.ar_items);
          } else {
            this.invoices = response.ar_items || [];
            this.rangeStart = 1;
            this.rangeEnd = 100;
            this.invoices.forEach((invoice) => {
              invoice.due_date = new Date(invoice.due_date);
              invoice.invoice_date = new Date(invoice.invoice_date);
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        }).finally (() => {
          // This text will be shown only if the user requested at least once the invoices and the list is empty  
          this.emptyTableLabel = 'No invoices have been found';
        })

      if (!isAddingInvoices) {
        this.page = 1;
        this.first = 0;
      }
    },
    formatCurrency(amount) {
      return Utils.formatPrice(amount);
      
    },
    handlePage(event) {
      /**
       *
       * LOGIC
       *
       * check whether current page is the last page on the paginator
       * if it is - get the next range to be added to be fetched - from the store
       * make the API call and append the result to the array in the store
       * set the next range to be fetched in the store
       */
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.invoices.length == this.rangeEnd
      ) {
        this.fetchInvoices(true);
      }
    },
    formatStringDate(dueDate) {
      return Utils.formatDate(dueDate);
    },
    lookUpInvoice(input, searchComponent) {
      if (input) {
        invoiceService.setInvoices(
          "1",
          "1",
          this.getCustomer.cust_id,
          this.getClient,
          "",
          "",
          "",
          "",
          input,
          "",
          "so_total so_status"
        ).then((response) => {
          if(response.ar_items?.length > 0) {
            if(response.ar_items[0].cust !== (this.getCustomer.custid || this.getCustomer.cust_id)) {
              this.addNotification({
                message: `Invoice #${input} is not associated with this customer.`,
                type: "error",
              });
            }
            else {
              this.rowClick({data:response.ar_items[0]})
            }
          } 
          else {
            this.addNotification({
              message: `Invoice #${input} was not found.`,
              type: "error",
            });
          }
        }).finally(() => {
          (searchComponent.$refs.autocomplete).searching = false;
          (searchComponent.$refs.autocomplete).$el.children[0].blur();
          (searchComponent.$refs.autocomplete).$el.children[0].focus();
        })
      }
    },
    isSelectedRow(data) {
      return this.getOrder.find((item) => item.ar_id === data.ar_id) && 'bg-blue-100';
    },
    handleAddressAdded() {
      custService.getCustomer(this.getCustomer.cust_id, this.getClient, "contact_email contact_name").then((response) => {
        this.setCustomerContacItems(response.contact_id_items);
      })
    },
    displayStatus(object) {
      return object?.ach_pending === "Y"
        ? "PAYMENT PENDING"
        : object.status === "P"
        ? "PAID"
        : "OUTSTANDING";
    },
  },
});
</script>

<style scoped>
.aging-item {
  border-bottom: 1px solid #f3f3f3;

  .item-label {
    color: black !important;
  }
}

.red-text {
  color: red !important;
}


</style>
