<template>
  <div class="flex justify-content-center">
    <div class="col-12 xl:col-10 grid">
      <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="Credit Card" class="m-3" style="width: 190px; justify-self:center" @click="payCC"
          :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i v-if='authorizing' class="pi pi-spin pi-spinner col-12" style="font-size: 8rem"></i>
            <i v-else class="pi pi-credit-card col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Credit Card</span>
          </div>
        </Button>
      </div>
      <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button :disabled="!this.getCustomer || hasZeroBalance" label="Card on File" class="m-3"
          style="width: 190px; justify-self:center" @click="payCOF">
          <div class='grid justify-content-center'>
            <i class="pi pi-folder-open col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Card on File</span>
          </div>
        </Button>
      </div>
      <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="Manual Card Entry" class="m-3" style="width: 190px; justify-self:center"
          @click="payCardNotPresent" :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i class="pi pi-pencil col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Manual Card Entry</span>
          </div>
        </Button>
      </div>
      <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="Cash" class="m-3" style="width: 190px; justify-self:center" @click="payCashClick"
          :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i class="pi pi-money-bill col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Cash</span>
          </div>
        </Button>
      </div>
      <div class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="Check" class="m-3" style="width: 190px; justify-self:center"
          @click="this.displayCheckModal = true" :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i class="pi pi-wallet col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Check</span>
          </div>
        </Button>
      </div>
      <div v-if="payment.achAllowed && !this.isPayout" class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="ACH" class="m-3" style="width: 190px; justify-self:center" @click="this.displayAchModal = true"
          :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i class="pi pi-desktop col-12" style="font-size: 8rem"></i>
            <span class='col-12'>e-Check</span>
          </div>
        </Button>
      </div>
      <div v-if="this.isPayout" class="col-12 md:col-6 lg:col-4 " style="display:grid">
        <Button label="Receipt Lookup" class="m-3" style="width: 190px; justify-self:center"
          @click="this.handleReceiptHistory" :disabled="hasZeroBalance">
          <div class='grid justify-content-center'>
            <i class="pi pi-ticket col-12" style="font-size: 8rem"></i>
            <span class='col-12'>Receipt Lookup</span>
          </div>
        </Button>
      </div>
    </div>
  </div>
  <Dialog v-model:visible='displayIframe' modal>
      <iframe v-resize="{
      log: false,
      heightCalculationMethod: 'documentElementScroll',
    }" :src="iframeUrl" :key="iframeKey" class="w-full" id="iframe" frameborder="0">
    </iframe>
    <div class="sm:col-12 md:col-12 lg:col-12 text-center" v-if="
      this.success
    ">
      <div class="text-center">
        <b>
          <i class="pi pi-check-circle mr-2 text-green-500"></i>
          <span class="text-green-500">Credit Card Validation Successful</span>
        </b>
      </div>
      <div v-if="!this.isPayout" class="p-inputgroup mt-2">
        <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
        <InputText autofocus v-model="this.amount" id="check" class="text-sm" />
      </div>
    </div>
    <template #footer>
      <Button v-if="this.success" @click='submitPayment'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayCOF" modal header="Select a credit card for payment">
    <DataTable :value="this.getCustomer.credit_card_no_items" responsiveLayout="stack" selectionMode="single"
      breakpoint="1279px" class="p-datatable-sm" @row-click="rowClicked">
      <template #empty> No credit cards on file. </template>
      <Column field="cardholder_name" header="Credit Card Name" style="min-width: 12rem">
        <template #body="slotProps">
          {{ slotProps.data.cardholder_name }}
        </template>
      </Column>
      <Column field="credit_card_no" header="Credit Card Number" style="min-width: 12rem">
        <template #body="slotProps">
          {{ formatCardNumber(slotProps.data.credit_card_id) }}
        </template>
      </Column>
      <Column field="credit_card_exp" header="Expiry Date" style="min-width: 16rem">
        <template #body="slotProps">
          {{ formatExpDate(slotProps.data.credit_card_exp) }}
        </template>
      </Column>
    </DataTable>
    <div v-if="!this.isPayout" class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
      <InputText autofocus v-model="this.amount" id="check" class="text-sm" />
    </div>
    <template #footer>
      <Button v-if="this.success" @click='submitPayment'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayAchModal" modal header="Enter Bank Account Information"
    class="w-11 md:w-6 lg:w-5 xl:w-3">
    <div class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Account
        Number</small>
      <InputText autofocus v-model="this.account" id="check" class="text-sm"
        :class="{'p-invalid': submitted && v$.account.required.$invalid}" />
    </div>
    <div v-if="submitted && v$.account.required.$invalid" class="pl-2">
      <small class="p-error">{{ v$.account.required.$message }}</small>
    </div>

    <div class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color">Routing Number</small>
      <span class="p-input-icon-right w-full">
        <i class="pi pi-info-circle" v-tooltip.top="{
              value:
                '<img src=&quot;./images/check.png&quot; height=&quot;150&quot; width=&quot;278&quot;>',
              escape: true,
            }"></i>
        <InputText autofocus v-model="this.routing" id="check" class="text-sm input-with-icon"
          :class="{'p-invalid': submitted && v$.routing.required.$invalid}" />
      </span>
    </div>
    <div v-if="submitted && v$.routing.required.$invalid" class="pl-2">
      <small class="p-error">{{ v$.routing.required.$message }}</small>
    </div>

    <div v-if="!this.isPayout" class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
      <InputText v-model="this.amount" id="check" class="text-sm"
        :class="{'p-invalid': submitted && v$.amount.required.$invalid}" />
    </div>
    <div v-if="submitted && v$.amount.required.$invalid" class="pl-2">
      <small class="p-error">{{ v$.amount.required.$message }}</small>
    </div>

    <div class="grid mt-2 justify-items-center">
      <div v-if="submitted && v$.accountType.required.$invalid" class="pl-3">
        <small class="p-error">{{ v$.accountType.required.$message }}</small>
      </div>
      <div class="field-radiobutton col-6">
        <RadioButton id="checking" name="type" value="Checking" v-model="accountType" />
        <label for="checking">Checking</label>
      </div>
      <div class="field-radiobutton col-6">
        <RadioButton id="savings" name="type" value="Savings" v-model="accountType" />
        <label for="savings">Savings</label>
      </div>
    </div>


    <div v-if="submitted && v$.agreement.sameAs.$invalid" class="pl-2">
      <small class="p-error">{{ v$.agreement.sameAs.$message }}</small>
    </div>
    <div class="
            field-checkbox
            sm:col-12
            md:col-12
            lg:col-12
            align-items-start
            pl-0
            pb-0
            mb-0
          ">
      <Checkbox id="agreement" v-model="agreement" :binary="true"
        :class="{'invalid-agreement-checkbox': submitted && !agreement}" />
      <label for="agreement" class="text-justify text-sm">By submitting this payment, you acknowledge that you have
        authorized {{ session.client }} to debit your account, and
        you accept responsibility for any resulting fees.</label>
    </div>


    <template #footer>
      <Button @click='payACH'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayCheckModal" modal header="Enter Check Number">
    <div class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Check #</small>
      <InputText autofocus v-model="this.check" id="check" class="text-sm" />
    </div>
    <div v-if="!this.isPayout" class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
      <InputText v-model="this.amount" id="check" class="text-sm" />
    </div>
    <template #footer>
      <Button @click='payCheck'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayCcModal" modal header="Credit Card Authorized">
    <div class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
      <InputText v-model="this.amount" id="check" class="text-sm" />
    </div>
    <template #footer>
      <Button @click='submitPayment'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayCashModal" modal header="Enter Cash Amount">
    <div class="p-inputgroup mt-2">
      <small class="p-inputgroup-addon font-semibold general-label-color" :style="{ width: '104px' }">Amount</small>
      <InputText autofocus v-model="this.amount" id="check" class="text-sm" @keyup.enter="this.payCash" />
    </div>
    <template #footer>
      <Button @click='payCash'>Submit</Button>
    </template>
  </Dialog>
  <Dialog v-model:visible="displayReceiptModal" modal header="Select a Previous Credit Card Transaction">
    <!--Change filter to CC when ready-->
    <ReceiptHistory :payoutView="true" @row-clicked="handleRowClicked" />
    <template #footer>
      <Button v-if="this.success" @click='submitPayment'>Submit</Button>
    </template>
  </Dialog>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import store from "@/store/index";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import iframeResize from "iframe-resizer/js/iframeResizer";
import Dialog from "primevue/dialog";
import PaymentService from '@/services/PaymentService'
const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import RadioButton from "primevue/radiobutton"
import Checkbox from "primevue/checkbox"
import useVuelidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";
import ReceiptHistory from "./ReceiptHistory.vue";

export default defineComponent({
  name: "Pay",
  components: {
    Button,
    Dialog,
    Column,
    DataTable,
    InputText,
    RadioButton,
    Checkbox,
    ReceiptHistory
    //LoadingSpinner,
  },
  created() {
    this.amount = this.remainingBalance();
    // populate the payment iframe url if not already populated
    if(this.payment.iframeurl == null || this.payment.iframeurl == ""){
      store.dispatch("payment/fetchPaymentInfo", {
      option: "IFRAMEURL ACHFLAG CCADRFLAG",
      Client: this.session.name,
    });
  }
  },
  updated() {
    this.amount = this.remainingBalance();
  },
  computed: {
    ...mapState(["payment","session"]),
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "pos/getCustomer",
      getRegister: "pos/getRegister",
      getClient: "session/getClient",
      getUser: "session/getUser",
      getTenders: "pos/getTenders",
    }),
    isPayout () {
      return this.getOrder.some(ar => ar.type === "PO")
    },
    iframeUrl() {
      if (this.getRegister?.reg_id && this.getRegister?.store) {
        return this.payment.iframeurl + "&REG=" + this.getRegister.reg_id + "&STORE=" + this.getRegister.store
      }
      return this.payment.iframeurl;
    },
    hasZeroBalance() {
      return (this.remainingBalance() <= 0 && !this.isPayout) || this.remainingBalance() == 0 && this.isPayout;
    },
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveToken);
  },
  directives: {
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      amount: {
        required: helpers.withMessage("Amount is required", required)
      },
      routing: {
        required: helpers.withMessage("Routing number is required", required)
      },
      account: {
        required: helpers.withMessage("Account number is required", required)
      },
      agreement: {
        sameAs: helpers.withMessage("Please accept the agreement", sameAs(true))
      },
      accountType: {
        required: helpers.withMessage("Please select an account type", required)
      }
    }
  },
  data() {
    return {
      submitted: false,
      agreement: false,
      displayReceiptModal: false,
      displayAchModal: false,
      displayCheckModal: false,
      displayCOF: false,
      displayIframe: false,
      displayCcModal: false,
      success: false,
      successBitArr: [0, 0],
      token:'',
      expiry:'',
      tokenMessage: '',
      displayOrderSubmit: '',
      error:'',
      authorizing: false,
      type: '',
      tran_id: '',
      displayCashModal: false,
      check: "",
      amount: '',
      account: "",
      routing: "",
      accountType: "",
      resultId: "",
      iframeKey: 0,
    };
  },
  props: {
    orderTotal: Function
  },
  methods: {
    ...mapActions({
       clearOrder: "pos/clearOrder",
       setCustomer: "pos/setCustomer",
       clearCustomer: "pos/clearCustomer",
       addTender: "pos/addTender",
       updateTenderAmt: "pos/updateTenderAmount",
       addNotification: "notification/add",
    }),
    handleDialogClose() {
      this.displayIframe = false;
      this.success = false;
    },
    handleError(title, message) {
      this.addNotification({
        title: title,
        message: message,
        type: "error",
      });
      this.success = false;
      this.reloadIframe();
    },
    checkErrorResponse(token) {
      if (token?.errorMessage && token?.errorMessage !== "") {
        this.handleError("Error", token.errorMessage);
        return;
      }
      if (token?.status === "501" && token?.statusMessage !== "") {
        this.handleError("Error", token.statusMessage);
        return;
      }
    },
    remainingBalance() {
      let tenderAmount = 0
      this.getTenders.forEach((tender) => {
        tenderAmount += parseFloat(tender.check_amount)
      });
      return (Math.round((this.orderTotal() - tenderAmount) * 100) / 100).toFixed(2);
    },
    formatExpDate(dateString) {
      if (!dateString.includes("/"))
        return dateString[0] + dateString[1] + "/" + dateString[2] + dateString[3];
      return dateString;
    },
    handleRowClicked(data) {
      if(data){
        this.success = true;
        this.token = data.token;
        this.expiry = data.expiry;
      }
    },
    rowClicked(event) {
      this.success = true;
      this.token = event.data.credit_card_id;
      this.expiry = event.data.credit_card_exp;
    },
    handleReceiptHistory() {
      this.displayReceiptModal = true
    },
    payCOF() {
      this.displayCOF = true
    },
    payCash() {
      this.type = 'CA'
      const index = this.getTenders.findIndex((tender) => tender.payment_type === "CA");
      if(index > -1){
        let newTender = JSON.parse(JSON.stringify(this.getTenders[index]))
        newTender.check_amount = (parseFloat(this.amount) + parseFloat(newTender.check_amount)).toFixed(2).toString()
        this.updateTenderAmt({tenderIndex: index, tender: newTender})
      } else {
        this.addTender({payment_type: this.type, check_amount: parseFloat(this.amount).toFixed(2).toString()})
      }
      this.amount = ''
      this.displayCashModal = false
    },
    async payACH() {
      this.submitted = true
      if (!this.agreement || this.accountType === '') {
        return
      }
      const isFormCorrect = await this.v$.account.$validate() && await this.v$.routing.$validate() && await this.v$.amount.$validate() && await this.v$.accountType.$validate()
      
      if (!isFormCorrect) {
        return
      }

      this.type = 'ACH'
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        payment_type: this.type,
        bank_account: this.account,
        bank_routing: this.routing,
        account_type: this.accountType
      })
      this.amount = ''
      this.check = ''
      //location.reload()
      this.displayAchModal = false
      this.submitted = false
    },
    payCheck() {
      this.type = 'CK'
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        payment_type: this.type,
        check: this.check,
      })
      this.amount = ''
      this.check = ''
      //location.reload()
      this.displayCheckModal = false
    },
    submitPayment() {
      this.type = 'CC'  
      let creditcard_no_items = [{credit_card_id: this.token, creditcard_exp: this.expiry, creditcard_amt: this.amount, resultId: this.resultId}]
      this.addTender({
        check_amount: parseFloat(this.amount).toFixed(2).toString(),
        payment_type: this.type,
        creditcard_no_items: creditcard_no_items,
      })
      this.amount = ''
      this.check = ''
      //location.reload()
      this.displayCOF = false
      this.displayIframe = false
      this.displayCcModal = false
      this.displayReceiptModal = false
      this.token = ""
      this.expiry = ""
      this.resultId = ""
      this.success = false
    },
    formatStringDate(dueDate) {
      const reformatedDueDate = new Date(dueDate)
        ?.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .split("/")
        .join("-");

      return reformatedDueDate
    },
    formatCardNumber(card_id) {
      return "************" + card_id.substring(12, 16);
    },
    payCardNotPresent() {
      this.displayIframe = true;
      this.type = 'CC'
    },
    payCashClick() {
      if (!this.isPayout) {
        this.displayCashModal = true
      }
      else 
      {
        this.payCash()
      }
    },
    payCC() {
      this.authorizing = true
      this.type = 'CC'
      paymentService.getCcToken( this.getRegister.store, this.getRegister.reg_id)
      .then(response => {
        this.token = response.token
        this.expiry = response.expiry
        this.resultId = response.resultId
        this.authorizing = false
        if(!this.isPayout) {
          this.displayCcModal = true
        }
        else {
          this.submitPayment()
        }
        
      })
      .catch(() => {
        this.authorizing = false
      })
      
    },
    reloadIframe() {
      this.iframeSrc = ''; 
      this.iframeKey++; 
      setTimeout(() => {
        this.iframeSrc = this.getIframeUrl;
      }, 0);
    },  
    receiveToken(event) {
      if (event.origin.includes("gateway.total-computing.com")) {
        var token = event.data;
        if (token.success) {
          this.success = true;
          this.token = token.message;
          this.expiry = token.expiry;
        } else {
          this.checkErrorResponse(token);
        }
      }
    }
  },
  watch: {
    displayIframe(newValue) {
      if (!newValue) {
        this.handleDialogClose();
      }
    }
  }
});
</script>

<style scoped>
.input-with-icon {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  min-width: 100%;
}
</style>