
import { defineComponent } from "vue";
import store from "@/store";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Search from "@/components/Search.vue";
import Card from "primevue/card";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { mapActions, mapGetters, mapState } from "vuex";
import CustomerService from "@/services/CustomerService";
import PaymentService from "@/services/PaymentService";
import MessageBox from "@/components/MessageBox.vue";
import SplitButton from "primevue/splitbutton";
import EventBus, { AppEvents } from "@/utility/EventBus";

import RecentlyViewedCustomers from "@/components/Customers/RecentCustomers.vue";
import Utils from "@/utility/utils";
import Customer from "@/types/customer";

import Column from "primevue/column";
import DataTable from "primevue/datatable";
import SalesOrder, { LisItems } from "@/types/salesorder";
import BatchService from "@/services/BatchService";
import LocalDesktopService from "@/services/LocalDesktopService";

const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const custService = new CustomerService();
const batchService = new BatchService(process.env.VUE_APP_ABSTRACTION_API);
const localDesktopService = new LocalDesktopService();

export default defineComponent({
  components: {
    Button,
    InputText,
    Dialog,
    Search,
    Card,
    TabView,
    RecentlyViewedCustomers,
    TabPanel,
    MessageBox,
    SplitButton,
    DataTable,
    Column,
  },
  computed: {
    ...mapState(["pos"]),
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "pos/getCustomer",
      getReg: "pos/getRegister",
      getUser: "session/getUser",
      getAllCustomers: "customer/getAllCusts",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getFilter: "session/getFilter",
      getClient: "session/getClient",
      hideLoadCustomerButton: "mrkControl/hideLoadCustomerButton",
      hideDraftedOrders: "mrkControl/hideDraftedOrders",
      controlFieldValues: "fdict/controlFieldValues",
      fieldExists: "fdict/fieldExists",
      getStoredCartItems: "pos/getStoredCartItems",
      getAutoLoadDefaultCustomers: "pos/getAutoLoadDefaultCustomers",
      getPOSRegisterPrinter: "pos/getRegisterPrinter",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
      posAutoCreateCust: "mrkControl/posAutoCreateCust",
    }),
    isSalesRepMode(): boolean {
      return (
        this.getReg === null || this.getReg.reg_id === this.getUser.user_id
      );
    },
    showServiceRep(): boolean {
      return this.fieldExists("CUST", "SERVICE.REP");
    },
    unsavedOrdersCount(): string {
      if (this.unsavedOrders && this.unsavedOrders.length > 0) {
        return `${this.unsavedOrders.length}`;
      } else {
        return "";
      }
    },
    showRacks(): boolean {
      return this.unsavedOrders.some((order: any) => {
        return order.racks?.length > 0;
      });
    },
  },
  beforeUnmount(){
    EventBus.off(AppEvents.PosClearDraftOrders);
  },
  mounted() {
    this.autoFocusInput();

    if (
      this.getAllCustomers &&
      this.getAllCustomers.length === 0 &&
      this.getAutoLoadDefaultCustomers
    ) {
      this.fetchDefaultCustomers();
    }
    this.getUnsavedOrders();
    EventBus.on(AppEvents.PosClearDraftOrders, () => {
      this.getUnsavedOrders();
    });
  },
  updated() {
    this.autoFocusInput();
  },
  data() {
    return {
      showAddCustomerDialog: false,
      showCashAdd: false,
      showCashRemove: false,
      loanNote: "",
      loanCharge: "",
      puNote: "",
      puCharge: "",
      input: "",
      rangeStart: 1,
      rangeEnd: 100,
      rowSize: 50,
      firstRow: 0,
      first: 0,
      rows: 50,
      page: 0,
      totalRecords: 0,
      customerSearchTab: 0,
      showCloseRegisterDialog: false,
      showDeleteDraftOrderDialog: false,
      selectedUnsavedOrderIndex: -1,
      unsavedOrders: [],
      regActions: [
        {
          label: "Close Register",
          icon: "pi pi-lock",
          command: () => {
            this.showRegDialog();
          },
        },
        {
          label: "Add Cash to Drawer",
          icon: "pi pi-plus",
          command: () => {
            this.addCash();
          },
        },
        {
          label: "Withdraw Cash from Drawer",
          icon: "pi pi-minus",
          command: () => {
            this.removeCash();
          },
        },
        {
          label: "No Sale",
          icon: "pi pi-ban",
          command: () => {
            this.noSale();
          },
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchControls: "control/fetchControl",
      changeTitle: "title/changeTitle",
      setCust: "pos/setCustomer",
      clearCust: "pos/clearCustomer",
      clearReg: "pos/clearRegister",
      clearOrder: "pos/clearOrder",
      clearPos: "pos/clearPOS",
      clearTenders: "pos/clearTenders",
      closeClearRegister: "pos/closeClearRegister",
      logout: "session/logout",
      addPartToOrder: "pos/addPartToOrder",
      removeFromOrder: "pos/removeFromOrder",
      fetchAllCustomers: "customer/getAllCustomers",
      fetchCustomer: "customerInquiry/getCustomer",
      getMrkControl: "mrkControl/getMrkControl",
      addRecentlyViewedCustomer: "customerInquiry/addRecentlyViewed",
      addNotification: "notification/add",
      clearStoredCart: "pos/clearStoredCartItems",
      removeUnsavedOrder: "pos/removeUnsavedOrder",
      replaceOrder: "pos/replaceOrder",
      setStoredCartItems: "pos/setStoredCartItems",
      setSalesOrder: "pos/setSalesOrder",
      fetchCustomers: "customer/getAllCustomers",
      fetchCacheParts: "pos/fetchCacheParts",
      fetchCachePrices: "pos/fetchCachePrices",
      fetchUnsavedOrders: "pos/getUnsavedOrders",
    }),
    formatRacks(rack_count: any) {
      if(rack_count) {
        return parseInt(rack_count).toLocaleString("en-US");
      } else {
        return "";
      }
    },
    handleClick() {
      (this.$refs.splitButton as any)?.$refs.button.$attrs.onClick();
    },
    showRegDialog() {
      this.showCloseRegisterDialog = true;
    },
    addCash() {
      this.showCashAdd = true;
    },
    removeCash() {
      this.showCashRemove = true;
    },
    createCust() {
      this.showAddCustomerDialog = false;
      const newCust = {name: this.input, cust_id: ""} as Customer; 
      const returnRecord = "Y"
      custService.newCustomer(newCust, returnRecord).then((response: any) => {
        if (response.status === "success") {
          this.addNotification({
            message: `Customer ${response.recordId} Created`,
            type: "success",
          });
          this.clearOrder();
          this.clearTenders();
          this.clearStoredCart();
          let cust = response.record;
          if (cust !== null && cust !== undefined) {
            this.setCust({
              cust_items: [cust],
            });
            this.addRecentlyViewedCustomer(cust);
            this.setSalesOrder({
              order: Utils.initNewOrderFromCustomer(
                cust,
                this.getSelectedCoCode,
                this.getSalesTaxCodes
              ),
              date_created: new Date(),
              racks: [],
            });
          }
        }
      });
    },
    handleRemoveUnsavedOrder(data: any) {
      this.showDeleteDraftOrderDialog = false;
      this.removeUnsavedOrder((this.unsavedOrders[data.index] as any).id);
      this.getUnsavedOrders();
    },
    printDateTime(data: string) {
      const date = new Date(data);
      if (date) {
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      } else {
        ("");
      }
    },
    handleLoadUnsavedOrder(data: any) {
      custService
        .getCustomer(
          data.data.order.sold_to,
          this.getClient,
          "contact_email contact_name rep_name"
        )
        .then((response: any) => {
          this.setCust({ cust_items: [response] });
          this.setSalesOrder(data.data);
        })
        .catch((error: any) => {
          this.addNotification({
            message: "Error searching for Customer",
            type: "error",
          });
        });
    },
    saveAllUnsavedOrders() {
      const payload = {
        newRecord: this.unsavedOrders.map((data: any) => {
          return data.order;
        }),
        filename: "SO",
        user: this.getUser.user_id,
      };

      // batchService.createRecords(payload)
      //   .then((resp) => {
      //     console.log(resp);
      //   }).catch((error) => {
      //     console.log(error);
      //   })
    },
    noSale() {
      let tenderList = [
        {
          register_no: this.getReg.reg_id,
          user: this.getUser.user_id,
          tran_type: "NS",
          li_items: [],
        },
      ];

      let tranId = "";
      paymentService.Pay(tenderList).then((response: any) => {
        if (response.details) {
          tranId = response.details[0].recordId;
        } else {
          tranId = response.recordId;
        }
        if (!(response.error === null || response.error === "" || response.error === undefined)) {
          this.addNotification({
            message: `Failed to Process No Sale Transaction. ${response.error}`,
            type: "error",
          });
        } else {
          if (response.status === "success") {
            paymentService
              .getReceipt(
                {key:tranId,
                user:this.getUser.user_id,
                reg:this.getReg.reg_id,
                email:"",
                format:"escpos"}
              )
              .then((response: any) => {
                localDesktopService.printReceipt(
                  response,
                  this.getPOSRegisterPrinter
                );
              });
          }
        }
      });
    },
    autoFocusInput() {
      (this.$refs.cust as any)?.$refs.autocomplete.$refs.focusInput.focus();
    },
    shippingAddress(customer: Customer) {
      return Utils.customerShippingAddress(customer);
    },
    convertOrderShippingAddressToArray(order: SalesOrder) {
      return Utils.convertOrderShippingAddressToArray(order);
    },
    handleCustomerMenuTabChange(event: any) {
      this.customerSearchTab = event.index;
    },
    clearCurrentOrder() {
      this.clearPos();
      this.clearCust();
    },
    // Ask about this function because of the rep mode
    cancelRestartOrder() {
      if (this.isSalesRepMode) {
        this.$router.push({ path: "/pos/parts" });
      }
    },
    handleLoadMoreCustomers() {
      this.customerSearchTab = 1;
      this.input = "";
      this.first = 0;
      this.page = 0;
      this.rangeStart = 1;
      this.rangeEnd = 100;
      this.fetchDefaultCustomers();
    },
    async fetchDefaultCustomers() {
      let filter_inactive_customers = "";
      if (this.getSelectedCoCode) {
        const data = await this.getMrkControl({
          client: store.getters["session/getClient"],
          selectedCode: this.getSelectedCoCode,
          refresh: false,
        });
        filter_inactive_customers = data.filter_inactive_customers || "";
      }

      const filterCustomers = filter_inactive_customers === "Y" ? "Y" : "";
      const reps = this.getFilter("CUST", "REP");
      this.fetchAllCustomers({
        selection: this.input,
        correls: "rep_name",
        rep: reps,
        activeOnly: filterCustomers,
        rangeStart: this.rangeStart.toString(),
        rangeEnd: this.rangeEnd.toString(),
      });
      this.totalRecords = this.getAllCustomers.length;
    },
    loadMoreCustomers() {
      this.rangeStart = 1;
      this.rangeEnd += 100;
      this.fetchDefaultCustomers();
    },
    handleCustomerSearch(data: any) {
      if (data) {
        this.input = data;
        this.customerSearchTab = 1;
        this.page = 0;
        this.first = 0;
        this.rangeStart = 1;
        this.rangeEnd = 100;
        this.firstRow = 0;
      }
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      this.rangeStart = event.first + 1;
      this.rangeEnd = event.first + event.rows;
      this.loadMoreCustomers();
    },
    setFirstRow(row: any) {
      this.firstRow = row;
    },
    addPUandPay() {
      this.showCashRemove = false;
      const li = [
        {
          li: "1",
          arid: "PU",
          type: "PU",
          amount: parseFloat(this.puCharge).toFixed(2).toString(),
          balance: parseFloat(this.puCharge).toFixed(2).toString(),
          ar_app_amt: parseFloat(this.puCharge).toFixed(2).toString(),
        },
      ];
      let tenderList = [
        {
          register_no: this.getReg.reg_id,
          user: this.getUser.user_id,
          tran_type: "PU",
          li_items: li,
        },
      ];

      let tranId = "";
      paymentService.Pay(tenderList).then((response: any) => {
        if (response.details) {
          tranId = response.details[0].recordId;
        } else {
          tranId = response.recordId;
        }
        if (!(response.error === null || response.error === "" || response.error === undefined)) {
          this.addNotification({
            message: `Failed to Process Cash Removal. ${response.error}`,
            type: "error",
          });
        } else {
          if (response.status === "success") {
            paymentService
              .getReceipt({
                key:tranId,
                user:this.getUser.user_id,
                reg:this.getReg.reg_id,
                email:"",
                format:"escpos"}
              )
              .then((response: any) => {
                localDesktopService.printReceipt(
                  response,
                  this.getPOSRegisterPrinter
                );
              });
          }
        }
      });
      this.showCashRemove = false;
    },
    addLoanAndPay() {
      const li = [
        {
          li: "1",
          arid: "LOAN",
          type: "LOAN",
          amount: parseFloat(this.loanCharge).toFixed(2).toString(),
          balance: parseFloat(this.loanCharge).toFixed(2).toString(),
          ar_app_amt: parseFloat(this.loanCharge).toFixed(2).toString(),
        },
      ];
      let tenderList = [
        {
          register_no: this.getReg.reg_id,
          user: this.getUser.user_id,
          tran_type: "LOAN",
          li_items: li,
          check_amount: parseFloat(this.loanCharge).toFixed(2).toString(),
          payment_type: "CA",
        },
      ];
      let tranId = "";
      paymentService.Pay(tenderList).then((response: any) => {
        if (response.details) {
          tranId = response.details[0].recordId;
        } else {
          tranId = response.recordId;
        }
        if (!(response.error === null || response.error === "" || response.error === undefined)) {
          this.addNotification({
            message: `Failed to Process Cash Removal. ${response.error}`,
            type: "error",
          });
        } else {
          if (response.status === "success") {
            paymentService
              .getReceipt({key:tranId,
                user:this.getUser.user_id,
                reg:this.getReg.reg_id,
                email:"",
                format:"escpos"}
              )
              .then((response: any) => {
                localDesktopService.printReceipt(
                  response,
                  this.getPOSRegisterPrinter
                );
              });
          }
        }
      });
      this.showCashAdd = false;
    },
    removePart(event: any, index: any) {
      event.stopPropagation();
      this.removeFromOrder(index);
    },
    rowClick(event: any) {
      this.clearOrder();
      this.clearTenders();
      this.clearStoredCart();
      this.addRecentlyViewedCustomer(event.data);
      let cust = event.data.cust_id;
      custService
        .getCustomer(cust, this.getClient, "contact_email contact_name rep_name")
        .then((response: any) => {
          this.setSalesOrder({
            order: Utils.initNewOrderFromCustomer(
              response,
              this.getSelectedCoCode,
              this.getSalesTaxCodes
            ),
            date_created: new Date(),
            racks: [],
          });
          this.setCust({ cust_items: [response] });
        })
        .catch((error: any) => {
          this.addNotification({
            message: "Error searching for Customer",
            type: "error",
          });
        });
    },
    changeToolBarTitle(sideMenuLabel: any) {
      this.changeTitle(sideMenuLabel.label);
    },
    closeClear() {
      this.closeClearRegister();
    },
    getUnsavedOrders() {
      const test = this.fetchUnsavedOrders();
      test.then((response: any) => {
        this.unsavedOrders = response;
      });
    },
  },
  watch: {
    getAllCustomers: function (customers) {
      if (customers.cust_items.length === 1) {
        this.clearOrder();
        this.clearTenders();
        this.clearStoredCart();
        this.addRecentlyViewedCustomer(customers.cust_items[0]);
        let cust = customers.cust_items[0].cust_id;
        // TODO: How is this supposed to work? How would the next API call know which so_items to get? 
        const so_items = customers.cust_items[0]?.so_items || [];
        const ar_items = customers.cust_items[0]?.ar_items || [];
        custService
          .getCustomer(cust, this.getClient, "contact_email contact_name rep_name")
          .then((response: any) => {
            this.setSalesOrder({
              order: Utils.initNewOrderFromCustomer(
                response,
                this.getSelectedCoCode,
                this.getSalesTaxCodes
              ),
              date_created: new Date(),
              racks: [],
            });
            this.setCust({
              cust_items: [response],
              ar_items: customers.ar_items || ar_items,
              so_items: customers.so_items || so_items,
            });
          })
          .catch((error: any) => {
            this.addNotification({
              message: "Error searching for Customer",
              type: "error",
            });
          });
      }
      if(customers.cust_items.length === 0 && this.posAutoCreateCust) {
        this.showAddCustomerDialog = true;
      }
    },
    getCustomer: function (customer) {
      if (customer === null) {
        this.autoFocusInput();
      }
    },
  },
});
