import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class ActionService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async postAction(code?: string, datafields?: any, register?: any) {
    return ClientAPI.Post(`actions/${code}`, {code: code, datafields : datafields, register : register})
  }
}
