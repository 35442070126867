import ClientAPI from "./ClientAPI";
import ApiService from "./ApiService";

export default class PoService extends ApiService {

  async getPOById(id: string) {
    const params = { id }
    return ClientAPI.Get(`po/${id}`, params)
  }

  async updatePO(payload: any) {
    return ClientAPI.Put(`po`, payload)
  }

  async postPO(po: any) {
    return ClientAPI.Post(`po`, po)
  }
}
