
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";

import useVuelidate from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import PaymentService from '@/services/PaymentService';
import LocalDesktopService from "@/services/LocalDesktopService";

const paymentService = new PaymentService(process.env.VUE_APP_ABSTRACTION_API);
const localDesktopService = new LocalDesktopService();

export default defineComponent({
  name: "SendReceiptDialog",
  components: {
    Button,
    InputText,
    Dialog
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    transId: {
      type: String,
      default: ""
    }
  },
  emits: ["onClose", "onSent"],
  data() {
    return {
      isLoading: false,
      showDialog: this.visible,
      email: ""
    };
  },
  computed: {
    ...mapGetters({
      getChangeAmount: "pos/getChangeAmount",
      getUser: "session/getUser",
      getReg: "pos/getRegister",
      getCustomer: "pos/getCustomer",
      getPOSRegisterPrinter: "pos/getRegisterPrinter"
    }),
    getModalTitle() {
      return `Send Receipt${this.label ? ': ' + this.label : this.transId ? ': ' + this.transId : ''}`
    }
  },
  created() {
    this.email = this.getCustomer.email || ""
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      email: {
        email: helpers.withMessage("Email not valid", email),
      }
    }
  },
  updated() {
    this.showDialog = this.visible;
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    closeDialog() {
      this.showDialog = false;
      this.email = ''
      this.$emit("onClose");
    },
    emailReceipt() {
      this.isLoading = true;
      if(this.getChangeAmount) {
        //call to drawer
        paymentService.getDrawerCodes(this.transId, this.getUser.user_id, this.getReg?.reg_id, true).then((drawerResp: any)=>{
          localDesktopService.printReceipt(drawerResp,this.getPOSRegisterPrinter)  
          this.sendEmail()
        })
      } 
      else {
        this.sendEmail()
      } 
    },
    sendEmail() {
      paymentService.getReceipt({key:this.transId, user:this.getUser.user_id, reg:this.getReg?.reg_id, email:this.email}).then((response: any)=>{
        this.addNotification({   
          message: `Receipt has been emailed to ${this.email} `,
          type: "success",
        });
        this.email = ''
        this.$emit("onSent");
        this.isLoading = false;
        this.showDialog = false;
      })
    }
  }
});
