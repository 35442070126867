
import { computed, defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Datatable from 'primevue/datatable';
import InputNumber from "primevue/inputnumber";
import Column from "primevue/column";
import Card from "primevue/card";
import Utils from "@/utility/utils";
import VendorAutocomplete from "../Autocompletes/VendorAutocomplete.vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";

export default defineComponent({
    name: "Ship",
    data () {
        return {
            vendor: {} as any,
            line_items: [] as any,
        }
    },
    created() {
        const set_line_items = this.setLis(this.getOrder[0].lis_items);
        this.line_items = set_line_items.filter((li: any) => parseFloat(li.li_order_qtys) != parseFloat(li.li_total_ship));
        let mutatedLis = JSON.parse(JSON.stringify(this.getOrder))
        mutatedLis[0].lis_items = set_line_items;
        this.setStoredCartItems({
            items: mutatedLis,
            type: 'summaryOrder',
        });
        this.replaceOrder(mutatedLis);
    },
    components: {
        Datatable,
        Column,
        VendorAutocomplete,
        InputNumber,
        Card
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
        line_items: {
            $each: helpers.forEach ({
                vendor: {
                    required: helpers.withMessage(
                        'Vendor is required for Non-Stock items', 
                        function(value, item) {
                            // Check if part_type is "L" for the current item
                            return item.part_type === 'L' ? !!value : true;
                        }
                    )
                }
            })
        }
        };
    },
    computed: {
        ...mapGetters({
            getOrder: "pos/getCurrentOrder",
            getCustomer: "pos/getCustomer",
            prohibitOpenPoShipments: "mrkControl/prohibitOpenPoShipments",
        }),
    },
    methods: {
        ...mapActions({ 
            updateShipLine: "pos/updateShipLine",
            replaceOrder: "pos/replaceOrder",
            setStoredCartItems: "pos/setStoredCartItems",
        }),
        getMax(data: any) {
            const { part_type, po_status, li_total_ship, curr_avail, li_order_qtys, po_id } = data;

            if (this.prohibitOpenPoShipments) {
                if (part_type === 'L' && po_status !== "C") {
                    return 0;
                }
                if (part_type !== 'L' && po_status !== "C" && po_id) {
                    return 0;
                }
            }

            const availableQty = parseInt(curr_avail);
            const orderQty = parseInt(li_order_qtys);
            const totalShip = parseFloat(li_total_ship);

            if (availableQty <= 0) {
                return 0;
            }

            if (li_total_ship) {
                return Math.min(availableQty, orderQty - totalShip);
            }

            if (availableQty < orderQty) {
                return availableQty;
            }

            return orderQty;
        },
        setLis(lis: any) {
            return lis
                .map((li: any) => {
                    return {
                        ...li,
                        li_ship_qtys: this.getMax(li),
                        vendor: li.vendor || '',
                    };
                });
        },
        formatPrice(amount: number | string) {
            return Utils.formatPrice(amount);
        },
        handleVendorSelected(vendor: any, line: any) {
            this.vendor.vendor = vendor.vendor_id;
            this.vendor.vendor_name = vendor.name;
            line.data.vendor = vendor;
            this.updateShipLine({liIndex: parseInt(line.data.lis) - 1, li: line.data});
        },
    },
});
