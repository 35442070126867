
  import { defineComponent } from "vue";
  import { mapActions, mapGetters } from "vuex";
  import POSCartDialog from "./POSCartDialog.vue";
  import EventBus, {AppEvents} from "@/utility/EventBus"
  import { cloneDeep, isEqual } from "lodash";
  import store from "@/store";
  import { ADD_ERROR_NOTIFICATION } from "@/types/notification";

  import { Fdict } from "@/types/fdict";
  
  export default defineComponent({
    components: {
      POSCartDialog,
    },
    emits: ["update:visible", "update:order", "processedRefund"],
    props: {
      selectedLineItemIndex: {
        type: Number,
        default: -1,
      },
      lineItems: {
        type: Array,
        default: () => [],
      },
      visible: {
        type: Boolean,
        default: false,
      },
      order: {
        type: Object,
        required: true,
      }
    },
    computed: {
      ...mapGetters({
        getCustomer: "pos/getCustomer",
        getField: "fdict/getField",
        getHidePosCustomLineItems: "pos/getHidePosCustomLineItems",
        posMaintainCartSort: "mrkControl/posMaintainCartSort",
        getCustomFields: "fdict/customFields",
      }),
      getLineItems(): any[] {
        return this.lineItems.map((lineItem: any) => {
          return {
            ...lineItem,
            key: lineItem.part,
            lis: lineItem.li,
            li_parts: lineItem.part,
            li_order_qtys: lineItem.qty,
            li_prices: lineItem.price,
            wrap_desc: lineItem.description,
          }});
      },
      lineItemCustomFields(): any[] {
        if (this.getHidePosCustomLineItems) {
          return [];
        } 
  
        if (this.ARFDictItems?.field_no_items) {
          return this.getCustomFields("AR", 31); // TODO: FDICT_AR.LI
        } 
        
        return [];
      },
      allowEditing(): boolean {
        return this.$route.fullPath.includes("pos/invoices");
      },
    },
    async created() {
      this.ARFDictItems = await this.getFdicts("AR");
    },
    data() {
      return {
        selectedLineItems: [] as any[],
        showRemoveConfirm: false,
        indexToRemove: -1,
        ARFDictItems: {} as Fdict,
      };
    },
    methods: {
      ...mapActions({
        addNotification: "notification/add",
        getFdicts: "fdict/fetchFdict",
        postARRefund: "accountsReceivable/postARRefund",
      }),
      handleOpenDialog() {
        this.selectedLineItems = this.lineItems;
      },
      posCloseCartDialog() {
        this.$emit('update:visible', false);
        EventBus.emit(AppEvents.PosFocusPartSearch, true);
      },
      async processRefund(newLineItems: any) {
        const order = this.updateInvoiceLineItems(newLineItems);

        const resp = await this.postARRefund(order);
        if (resp.response.status?.toLowerCase() === "failed") {
            store.dispatch(ADD_ERROR_NOTIFICATION, {
              message: resp.response.error,
          });
          return;
        }
        this.$emit('processedRefund', order);
        EventBus.emit(AppEvents.PosReloadInvoices, true);
      },
      convertLineItems(lineItems: any[]) {
        lineItems = lineItems.map((lineItem: any) => {
          lineItem.qty = lineItem.li_order_qtys?.toString();
          lineItem.price = lineItem.li_prices?.toString();
          lineItem.description = lineItem.wrap_desc;
        });
      },
      
      getLineItemChanges(oldLineItem: any, newLineItem: any) {
        let changes: string[] = [];
        if (+newLineItem.li_order_qtys !== +oldLineItem?.li_order_qtys) {
          changes.push("Quantity");
        }
        if (+newLineItem.li_prices !== +oldLineItem?.li_prices) {
          changes.push("Price");
        }
        if (newLineItem.wrap_desc !== oldLineItem?.wrap_desc) {
          changes.push("Description");
        }
        if (newLineItem.li_notes !== oldLineItem?.li_notes) {
          changes.push("Notes");
        }
  
        // Check for changes in custom_fields
        if (oldLineItem) {
          Object.keys(newLineItem.custom_fields).forEach((key) => {
            if (!isEqual(newLineItem.custom_fields[key], oldLineItem.custom_fields[key])) {
              changes.push(key.charAt(0).toUpperCase() + key.slice(1));
            }
          });
        }
        return changes;
      },
      removePartClick(event: any, indexToRemove: number, lineItems: any) {
        this.updateInvoiceLineItems(lineItems.filter((item: any, index: number) => index !== indexToRemove));
      },
      updateInvoiceLineItems(newLineItems: any) {
        this.convertLineItems(newLineItems);   

        const order = cloneDeep(this.order);
        order.li_items = newLineItems;
        
        this.$emit("update:order", order);

        return order;
      },
    },
    provide() {
      return {
        tableTitle: "Refund Entry",
      };
    },
  });
  