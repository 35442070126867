
import { defineComponent } from "vue";
import PosForm from "@/components/PosForm.vue";
import Footer from "@/components/Footer.vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  components: {
    PosForm,
    Footer,
    Dialog,
    Button,
    ProgressSpinner,
    LoadingSpinner
  },
  created() {
    this.updateDateAtMidnight();
  },
  methods: {
    ...mapActions({
      closeClearRegister: "pos/closeClearRegister",
    }),
    updateDateAtMidnight() {
      if(this.getRegister?.status === 'Open') {
        const now = new Date();
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const timeToMidnight = tomorrow.getTime() - now.getTime();
        setTimeout(() => {
          this.currentDate = new Date().getDate();
          this.updateDateAtMidnight();
        }, timeToMidnight);
      }
    },
  },
  computed: {
    ...mapGetters({
      getStoredCartItems: "pos/getStoredCartItems",
      getSalesOrder: "pos/getSalesOrder",
      getCustomer: "pos/getCustomer",
      getSessionStart: "session/getSessionStartDateStamp",
      getRegister: "pos/getRegister",
      getValidationLoading: "pos/getValidationLoading",
    }),
    expiredRegSession(): boolean {
      return (this.currentDate !== this.getSessionStart && this.getRegister?.status === 'Open')
      || (new Date(this.getRegister?.last_init_date).getDate() !== new Date().getDate() && this.getRegister?.status === 'Open');
    },
  },
  data() {
    return {
      currentDate: new Date().getDate(),
      closingReg: false,
    };
  },
  watch: {
    getRegister(newVal, oldVal) {
      if (oldVal?.status === 'Open' && newVal?.status === 'Closed') {
        this.closingReg = true;
      }
    }
  },
  async beforeRouteLeave(_, _2, next: (condition?: boolean) => void) {
    if (
      this.getSalesOrder.lis_items?.length
      || this.getStoredCartItems.summaryOrder?.length
      || this.getStoredCartItems.orderInvoice?.length
    ) {
      const res: boolean = await(
        this.$refs['posForm'] as any
      ).onLeavingPOS();
      next(res);
    }
    next(true);
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.fullPath.includes('pos') && to.fullPath === '/pos/customers') {
      if (
      this.getCustomer
      && (this.getSalesOrder.lis_items
      || this.getStoredCartItems.summaryOrder.length
      || this.getStoredCartItems.orderInvoice.length)
      ) {
        const res: boolean = await (this.$refs['posForm'] as any).onLeavingPOS();
        next(res);
      }
    }
    next();
  }
});
